<template>
  <div class="wrapper">
    <div class="content">
      <div class="container">
        <h1>Nosotros</h1>
        <img src="@/assets/images/logo_nosotros.svg" alt="" />
        <h2>¿Qué hacemos?</h2>
        <p>
          Solutz tiene como misión reducir los costes energéticos de otras
          empresas y favorecer la transición energética hacia un sistema libre
          de emisiones.
        </p>
        <p>
          Para ello nuestra empresa consta de dos áreas principales: consultoría
          y desarrollo de productos tecnológicos.
        </p>
        <p>
          Agregamos el consumo de empresas para poder mejorar sus condiciones de
          compra energética y ofrecemos estudios personalizados de instalaciones
          de generación renovable.
        </p>
        <p>
          Nuestro software de desarrollo propio utiliza miles de datos
          históricos para extraer conclusiones sobre el perfil de cada
          consumidor y minimizar el coste de su compra energética.
        </p>
        <p>
          Nos anticipamos a los cambios en tendencias y normativas para crear
          herramientas digitales que favorezcan una transición dinámica.
        </p>
        <p>
          Los cambios normativos previstos para 2022 relacionados a la
          flexibilidad de la demanda crean una gran oportunidad y estamos
          centrando nuestros esfuerzos en el desarrollo de un software que se
          ajuste a las necesidades de nuestros clientes y les genere un mayor
          ahorro.
        </p>
        <h2 style="margin-top:60px;">RSC</h2>

        <p>
          Creemos firmemente en que, como empresa del sector energético, debemos
          demostrar nuestro compromiso con la transición energética mediante
          nuestros actos y hacer todo lo que esté en nuestra mano para acelerar
          la llegada de un sistema verde, descentralizado y justo.
        </p>
        <p>
          Es por ello por lo que únicamente ofrecemos energía con certificado de
          origen renovable e instalamos plantas de generación renovable, además,
          invertimos nuestro tiempo y recursos en innovación energética.
        </p>
        <h2 style="margin-top:60px;">Ventajas del Agragador Independiente</h2>
                  <p>
                    La agregación de demanda tiene ventajas claras para el consumidor final y para la gestionabilidad de la propia red, estas son algunas de ellas:
                    <ul>
                      <li>Mejora de condiciones económicas ya que gracias la volumen gestionado obtenemos un mayor poder de negociación.</li>
                      <li>Energía 100% con certificado de origen renovable.</li>
                      <li>Intermediación favorable para el consumidor: Protección ante conflictos con empresas comercializadoras y distribuidoras, nos posicionamos contigo siempre y simplificamos procesos con total transparencia.</li>
                      <li>Tecnología para la gestión de la demanda energética: Innovamos constantemente para poder ofrecer el mejor sistema de gestión de demanda disponible.</li>
                    </ul>
                  </p>

        <div style="margin: 60px 0">
          <router-link to="/contacto"
            ><button>Quiero unirme</button></router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  mounted() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/styles/variables";
.wrapper {
  margin-top: 120px;
  padding-top: 50px;
  padding-bottom: 200px;
  background-image: url(../assets/images/wavy_background_bottom_lay.svg);
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  .content {
    .container {
      width: calc(100% - 80px);
      padding: 15px 40px;
      background-color: $dirty-white;
      h1,
      h2,
      p {
        text-align: left;
      }
      img {
        height: 200px;
      }
    }
  }
}
</style>
